import { FilterButton } from "./filterButton";
import { SearchBar } from "./searchBar";
import { useStoreMapContext } from "../../contextProviders";

export function Filters() {
    const { sidePanelContentSignal } = useStoreMapContext();
    const [sidePanelContent] = sidePanelContentSignal;
    const classes = () =>
        sidePanelContent()
            ? "max-sm:-translate-y-full sm:-translate-x-full"
            : "";

    return (
        <div
            class={`${classes()} absolute z-10 flex w-full flex-col justify-between gap-0 p-0 transition-all duration-300 ease-in-out sm:mt-4 sm:w-auto sm:max-w-[100vw] sm:flex-row sm:gap-4 sm:pl-4`}
        >
            <SearchBar />
            <FilterButton />
        </div>
    );
}
