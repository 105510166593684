import { SearchBoxSuggestion } from "@mapbox/search-js-core";
import {
    createEffect,
    createResource,
    createSignal,
    For,
    Show
} from "solid-js";
import { produce } from "solid-js/store";
import { useMapContext } from "solid-map-gl";

import { useStoreMapContext, useTranslator } from "../../contextProviders";
import { parseCountryCodes } from "../../countries";

export function SearchBar() {
    const { t, currentLanguage } = useTranslator();
    const {
        appliedFilterStore,
        mapOptions,
        mapboxSearch,
        selectedPlaceSignal: [selectedPlace, setSelectedPlace]
    } = useStoreMapContext();
    const [appliedFilters, setAppliedFilters] = appliedFilterStore;
    const [ctx] = useMapContext();

    const [searchTerm, setSearchTerm] = createSignal<string>();

    const resetSearchFilter = () => {
        setAppliedFilters(
            produce((af) => {
                af.search.text = "";
            })
        );
    };

    const fetchSearchSuggestions = async (query: string) => {
        if (!query) {
            return [];
        }

        return await mapboxSearch.suggest(
            parseCountryCodes(mapOptions.countryCodes),
            currentLanguage,
            query,
            5
        );
    };

    const selectSuggestion = async (suggestion: SearchBoxSuggestion) => {
        setSelectedPlace(
            await mapboxSearch.retrieve(currentLanguage, suggestion.mapbox_id)
        );
    };

    createEffect(() => {
        const map = ctx.map as mapboxgl.Map;
        const place = selectedPlace();
        if (!place) return;

        if (place.properties.bbox !== undefined) {
            map.fitBounds(place.properties.bbox);
        } else {
            map.flyTo({
                center: place.geometry.coordinates as mapboxgl.LngLatLike,
                zoom: 17
            });
        }
    });

    const [suggestions] = createResource(searchTerm, fetchSearchSuggestions);
    const [showSuggestions, setShowSuggestions] = createSignal(false);

    const selectFirstSuggestion = async () => {
        const firstSuggestion = suggestions()?.[0];
        if (!firstSuggestion) return;
        await selectSuggestion(firstSuggestion);
        setShowSuggestions(false);
    };

    return (
        <div class="sm:max-w-120 flex w-full flex-col bg-white sm:w-auto sm:rounded-md sm:shadow-lg">
            <div class="flex min-h-14 flex-auto items-center gap-4 px-4 py-1">
                <input
                    class="h-full min-w-0 flex-1 focus:outline-none"
                    type="text"
                    placeholder={t.sf__search__placeholder()}
                    value={selectedPlace()?.properties.name || ""}
                    onKeyUp={async (e) => {
                        if (e.key === "Enter") {
                            e.currentTarget.blur();
                            await selectFirstSuggestion();
                        } else {
                            if (appliedFilters.search.text) {
                                resetSearchFilter();
                            }

                            setSearchTerm(e.currentTarget.value);
                            setShowSuggestions(true);
                        }
                    }}
                />
                <Show when={!!searchTerm() || selectedPlace()}>
                    <img
                        onClick={() => {
                            setSearchTerm("");
                            setSelectedPlace(undefined);
                            resetSearchFilter();
                        }}
                        class="mr-2 size-full w-6 self-center hover:cursor-pointer"
                        src={`${import.meta.env.VITE_CDN_BASE_URL}images/icons/clear.svg`}
                    />
                </Show>

                <img
                    class="size-full w-6 self-center hover:cursor-pointer"
                    onClick={selectFirstSuggestion}
                    src={`${import.meta.env.VITE_CDN_BASE_URL}images/icons/search.svg`}
                    alt="search"
                />
            </div>
            <Show when={showSuggestions()}>
                <For each={suggestions()}>
                    {(s) => {
                        const suggestionText =
                            s.name + ", " + s.place_formatted;

                        return (
                            <button
                                class="block w-full cursor-pointer truncate text-nowrap rounded-none border-t bg-gray-100 px-4 py-2 text-left last:border-b hover:bg-gray-200 md:border-b-0 md:last:rounded-b-md"
                                title={suggestionText}
                                onClick={async () => {
                                    setSearchTerm(suggestionText);
                                    await selectSuggestion(s);
                                    setShowSuggestions(false);
                                }}
                                textContent={suggestionText}
                            />
                        );
                    }}
                </For>
            </Show>
        </div>
    );
}
