import { createEffect, onCleanup } from "solid-js";

interface Callback<T extends Event = Event> {
    (event: T): void;
}

export default function useClickOutside(ref: HTMLElement, callback: Callback) {
    createEffect(() => {
        const listener = (event: Event) => {
            const element = ref;
            if (!element || element.contains(event.target as Node)) {
                return;
            }

            callback(event);
        };
        document.addEventListener("click", listener);

        onCleanup(() => document.removeEventListener("click", listener));
    });
}
