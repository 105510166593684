import * as de from "../l10n/locales/de/translations.json";
import * as en from "../l10n/locales/en-CH/translations.json";
import * as fr from "../l10n/locales/fr/translations.json";
import * as it from "../l10n/locales/it/translations.json";

export type Language = keyof typeof dictionaries;

export type Translations = typeof en;

export const dictionaries = {
    de: de,
    en: en,
    fr: fr,
    it: it
} as const;

export const defaultLanguage: Language = "de";

const isSupported = (language: string): language is Language =>
    Object.hasOwn(dictionaries, language);

export const parseLanguage = (language?: string): Language => {
    language = language || defaultLanguage;

    if (isSupported(language)) {
        return language;
    } else {
        const allLanguages = Object.keys(dictionaries).join(", ");
        console.warn(
            `Could not parse language code '${language}'. Supported languages are: ${allLanguages}. Defaulting to '${defaultLanguage}'`
        );
        return defaultLanguage;
    }
};
