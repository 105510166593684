import { Language } from "./languages";

export type Service = (typeof knownServices)[number];

const knownServices = [
    "24/7",
    "Chimpy",
    "DPDParcel",
    "DitschSnackStandort",
    "OkProducts",
    "Paysafe",
    "Pudo DropOff",
    "SmartsGasStation",
    "SwisscomEasypoint",
    "TooGoodToGo",
    "UmbrellaRental",
    "UpsAccessPoint",
    "ApplePay",
    "GooglePay",
    "Maestro",
    "Mastercard",
    "UberEats",
    "VisaCard",
    "VPay"
] as const;

const untranslatableServiceNames = {
    "24/7": "24/7",
    Chimpy: "Chimpy",
    DitschSnackStandort: "DitschSnackStandort",
    DPDParcel: "DPD",
    OkProducts: "ok.–",
    Paysafe: "Paysafe card",
    SwisscomEasypoint: "Swisscom Easypoint",
    TooGoodToGo: "Too Good To Go",
    UpsAccessPoint: "UPS Access Point",
    ApplePay: "Apple Pay",
    GooglePay: "Google Pay",
    Maestro: "Maestro",
    Mastercard: "Mastercard",
    UberEats: "Uber Eats",
    VisaCard: "Visa Card",
    VPay: "V-Pay"
} as const;

const serviceNames: Record<Language, Record<Service, string>> = {
    de: {
        ...untranslatableServiceNames,
        "Pudo DropOff": "Päcklipunkt",
        SmartsGasStation: "Tankstelle",
        UmbrellaRental: "Schirmverleih"
    },
    en: {
        ...untranslatableServiceNames,
        "Pudo DropOff": "Päcklipunkt",
        SmartsGasStation: "Gas station",
        UmbrellaRental: "Umbrella rental"
    },
    fr: {
        ...untranslatableServiceNames,
        "Pudo DropOff": "Relais Colis",
        SmartsGasStation: "Station essence",
        UmbrellaRental: "Location de parapluie"
    },
    it: {
        ...untranslatableServiceNames,
        "Pudo DropOff": "Punto Pacco",
        SmartsGasStation: "Stazione di servizio",
        UmbrellaRental: "Noleggio di ombrelli"
    }
};

export const isSupportedService = (service: string): service is Service =>
    knownServices.indexOf(service as Service) >= 0;

export const filterForKnownServices = (services: string[]) =>
    services.filter(isSupportedService);

export const prettyPrintService =
    (language: Language): ((service: Service) => string) =>
    (service) =>
        serviceNames[language][service];
