import { Setter } from "solid-js";

import { useTranslator } from "../../contextProviders";
import { Service, prettyPrintService } from "../../services";
import { MultiSelectDropdown } from "../multiSelectDropdown";

export function ServicesSelect(props: {
    availableServices: Service[];
    selectedServices: Service[];
    setSelectedServices: Setter<Service[]>;
}) {
    const { t, currentLanguage } = useTranslator();

    const servicesDisplayText = () => {
        if (props.selectedServices.length === 0) {
            return t.sf__filters__services_none_selected();
        } else if (props.selectedServices.length > 1) {
            const count = props.selectedServices.length;
            return t.sf__filters__services_multiple_selected({ count });
        } else {
            const service = props.selectedServices[0];
            return prettyPrintService(currentLanguage)(service);
        }
    };

    const prettyPrint = prettyPrintService(currentLanguage);

    return (
        <MultiSelectDropdown
            label={t.sf__filters__services_label()}
            displayText={servicesDisplayText()}
            options={props.availableServices.toSorted((a, b) =>
                prettyPrint(a).localeCompare(prettyPrint(b))
            )}
            selectedOptions={props.selectedServices}
            formatOption={prettyPrint}
            onChange={props.setSelectedServices}
        />
    );
}
