import {
    For,
    createEffect,
    createResource,
    createSignal,
    Show
} from "solid-js";
import { produce } from "solid-js/store";

import { FormatsSelect } from "./formatsSelect";
import { ServicesSelect } from "./servicesSelect";
import { fetchAvailableFilters } from "../../api";
import { useStoreMapContext, useTranslator } from "../../contextProviders";
import { Format } from "../../formats";
import {
    OpeningHours,
    openingHoursOptions,
    prettyPrintOpeningHours
} from "../../openingHours";
import { Service, isSupportedService } from "../../services";
import { AvailableFilters, FilterParams } from "../../types";
import { buildStoreFinderOptions } from "../../utils";

export function FilterControls(props: { availableFilters: AvailableFilters }) {
    const { t } = useTranslator();
    const { appliedFilterStore, sidePanelContentSignal, mapOptions } =
        useStoreMapContext();
    const [appliedFilters, setAppliedFilters] = appliedFilterStore;
    const [_, setSidePanelContent] = sidePanelContentSignal;

    const closeOverlay = () => {
        setSidePanelContent(undefined);
    };

    const [openingHour, setOpeningHour] = createSignal<OpeningHours>("all");

    const [selectedFormats, setSelectedFormats] = createSignal(
        appliedFilters.formats
    );

    const initiallyAvailableServices: () => Service[] = () =>
        props.availableFilters.services;

    const [availableServices, setAvailableServices] = createSignal(
        initiallyAvailableServices()
    );

    const [selectedServices, setSelectedServices] = createSignal(
        appliedFilters.services
    );

    const fetchFilters = async (selectedFormats: Format[]) => {
        const newMapOptions: FilterParams = {
            ...mapOptions,
            formats: selectedFormats.join(",")
        };

        const { urlParams } = buildStoreFinderOptions(newMapOptions);

        return await fetchAvailableFilters(urlParams);
    };

    const [filters] = createResource(selectedFormats, fetchFilters);

    createEffect(() => {
        const currentFilters = filters();
        if (!currentFilters) {
            return;
        }

        const availableServices =
            currentFilters.services.filter(isSupportedService);

        setAvailableServices(availableServices);
    });

    const resetFilters = () => {
        setAppliedFilters(
            produce((af) => {
                af.openingHours = "all";
                af.formats = [];
                af.services = [];
            })
        );

        setOpeningHour("all");
        setSelectedFormats([]);
        setSelectedServices([]);
        setAvailableServices(initiallyAvailableServices());
    };

    const apply = () => {
        setSidePanelContent(undefined);

        setAppliedFilters(
            produce((af) => {
                af.openingHours = openingHour();
                af.formats = selectedFormats();
                af.services = selectedServices();
            })
        );
    };

    return (
        <div class="top-0 h-full w-full space-y-6">
            <header class="flex justify-between border-b border-divider-gray px-6 py-3">
                <div
                    class="cursor-pointer self-center font-medium text-blue-600 hover:underline"
                    onClick={closeOverlay}
                >
                    {t.sf__filters__back_button()}
                </div>
                <button
                    onClick={apply}
                    class="rounded-lg bg-cta-blue px-6 py-3 font-medium text-white"
                >
                    {t.sf__filters__save_button()}
                </button>
            </header>
            <main class="flex flex-col gap-6 px-6">
                <Show when={props.availableFilters.formats.length > 1}>
                    <FormatsSelect
                        selectedFormats={selectedFormats()}
                        setSelectedFormats={setSelectedFormats}
                    />
                </Show>

                <Show when={props.availableFilters.services.length > 1}>
                    <ServicesSelect
                        availableServices={availableServices()}
                        selectedServices={selectedServices()}
                        setSelectedServices={setSelectedServices}
                    />
                </Show>

                <div>
                    <div class="mb-3">
                        {t.sf__filters__opening_hours_label()}
                    </div>
                    <ul class="flex justify-between text-sm">
                        <For each={openingHoursOptions}>
                            {(openingHourOption) => (
                                <li
                                    class="flex grow cursor-pointer justify-center border border-r-0 border-gray-200 px-4 py-2 last:rounded-r last:border-r first-of-type:rounded-l hover:bg-gray-50 has-[:checked]:bg-gray-50 has-[:checked]:text-blue-600"
                                    onClick={() =>
                                        setOpeningHour(openingHourOption)
                                    }
                                >
                                    <input
                                        type="radio"
                                        name="openingHours"
                                        class="hidden"
                                        value={openingHourOption}
                                        id={openingHourOption}
                                        checked={
                                            openingHour() === openingHourOption
                                        }
                                    />
                                    <label
                                        class="cursor-pointer self-center pl-1 text-center"
                                        for={openingHourOption}
                                    >
                                        {prettyPrintOpeningHours(
                                            t,
                                            openingHourOption
                                        )}
                                    </label>
                                </li>
                            )}
                        </For>
                    </ul>
                </div>
            </main>
            <footer class="px-6 py-3">
                <div
                    onClick={resetFilters}
                    class="cursor-pointer font-medium text-red-500 hover:underline"
                >
                    {t.sf__filters__reset_filters()}
                </div>
            </footer>
        </div>
    );
}
