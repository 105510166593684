import mapboxgl from "mapbox-gl";

export type SupportedCountry = keyof typeof boundingBoxes;

const boundingBoxes = {
    AT: new mapboxgl.LngLatBounds([9.4, 46.3], [17.2, 49.04]),
    CH: new mapboxgl.LngLatBounds([5.95, 45.81], [10.5, 47.81]),
    DE: new mapboxgl.LngLatBounds([5.8, 47.1], [15.1, 54.98]),
    LU: new mapboxgl.LngLatBounds([5.72, 49.44], [6.55, 50.19]),
    NL: new mapboxgl.LngLatBounds([3.31, 50.7], [7.12, 53.6])
} as const;

const supportedCountries = Object.keys(boundingBoxes) as SupportedCountry[];

const isSupported = (country: string): country is SupportedCountry =>
    Object.hasOwn(boundingBoxes, country);

export const parseCountryCodes = (
    countryCodes: string = ""
): SupportedCountry[] => {
    const countries = countryCodes.toUpperCase().split(",").filter(isSupported);

    if (countries.length > 0) return countries;
    else return supportedCountries;
};

export const calculateBoundingBox = (countries: SupportedCountry[]) => {
    if (countries.length === 0) countries = supportedCountries;

    return countries.reduce(
        (bbox, country) => bbox.extend(boundingBoxes[country]),
        new mapboxgl.LngLatBounds()
    );
};
