import { ChainedTranslator } from "@solid-primitives/i18n";

import { Translations } from "./languages";

export type OpeningHours = (typeof openingHoursOptions)[number];

export const openingHoursOptions = ["all", "openAwhile", "open"] as const;

export const prettyPrintOpeningHours = (
    t: ChainedTranslator<Translations, string>,
    openingHours: OpeningHours
) => {
    switch (openingHours) {
        case "all":
            return t.sf__filters__opening_hours_all();
        case "openAwhile":
            return t.sf__filters__opening_hours_closing_soon();
        case "open":
            return t.sf__filters__opening_hours_open();
    }
};
