import { For, Match, Show, Switch, createSignal } from "solid-js";

import { useTranslator } from "../contextProviders";
import clickOutside from "../directives/clickOutside";

/** Toggles `optionToToggle` in `selectedOptions` and returns the selected options in the order they appear in `options`. */
const toggle = <T,>(
    options: readonly T[],
    selectedOptions: readonly T[],
    optionToToggle: T
): T[] => {
    const set = new Set(selectedOptions);
    set.delete(optionToToggle) || set.add(optionToToggle);

    return options.filter((opt) => set.has(opt));
};

export function MultiSelectDropdown<T>(props: {
    label: string;
    displayText: string;
    options: readonly T[];
    selectedOptions: readonly T[];
    formatOption: (option: T) => string;
    onChange: (selectedOptions: T[]) => void;
}) {
    const { t } = useTranslator();
    const [show, setShow] = createSignal(false);

    const Options = () => (
        <div class="z-10" ref={(e) => clickOutside(e, () => setShow(false))}>
            <div class="border-secondary-950 absolute flex w-full flex-col overflow-hidden rounded-b border border-t-0 bg-white shadow">
                <For each={props.options}>
                    {(opt) => (
                        <div
                            onClick={(e) => {
                                e.stopImmediatePropagation();
                                const selectedOptions = toggle(
                                    props.options,
                                    props.selectedOptions,
                                    opt
                                );
                                props.onChange(selectedOptions);
                            }}
                            class="flex w-full cursor-pointer flex-row justify-start gap-2 p-2 hover:bg-blue-50"
                        >
                            <Switch>
                                <Match
                                    when={
                                        props.selectedOptions.indexOf(opt) >= 0
                                    }
                                >
                                    <div class="w-5">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="icon icon-tabler icon-tabler-square-check"
                                            viewBox="0 0 24 24"
                                            stroke-width="2"
                                            fill="#3758F9"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke="#3758F9"
                                        >
                                            <path
                                                fill="#3758F9"
                                                d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
                                            />

                                            <path
                                                d="M9 12l2 2l4 -4"
                                                fill="none"
                                                stroke="white"
                                            />
                                        </svg>
                                    </div>
                                </Match>
                                <Match
                                    when={
                                        props.selectedOptions.indexOf(opt) < 0
                                    }
                                >
                                    <div class="w-5">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="icon icon-tabler icon-tabler-square"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                                        </svg>
                                    </div>
                                </Match>
                            </Switch>

                            <div>{props.formatOption(opt)}</div>
                        </div>
                    )}
                </For>
            </div>
        </div>
    );

    const noOptionsDropdown = (
        <div class="relative flex cursor-not-allowed flex-col items-start">
            <div class="mb-2">{props.label}</div>
            <div
                class={`black border-secondary-400 flex w-full items-center justify-between rounded border bg-gray-100 p-1 ps-2`}
            >
                <div class="pl-1">{t.sf__none()}</div>
            </div>
        </div>
    );

    const dropdown = (
        <div class="relative flex cursor-pointer flex-col items-start">
            <div class="mb-2">{props.label}</div>
            <div
                onClick={() => setShow(!show())}
                class={`black border-secondary-400 flex w-full items-center justify-between border bg-white p-1 ps-2 ${show() ? "border-secondary-950 border-b-secondary-200 rounded-t" : "rounded"}`}
            >
                <div class="pl-1">{props.displayText}</div>
                <div class="ml-2 flex h-8 items-center px-1">
                    <Switch>
                        <Match when={show()}>
                            <button type="button" class="h-6 w-6">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-chevron-up"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <path d="M6 15l6 -6l6 6" />
                                </svg>
                            </button>
                        </Match>
                        <Match when={!show()}>
                            <button type="button" class="h-6 w-6">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-chevron-down"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <path d="M6 9l6 6l6 -6" />
                                </svg>
                            </button>
                        </Match>
                    </Switch>
                </div>
            </div>
            <Show when={show()}>
                <Options />
            </Show>
        </div>
    );

    return <>{props.options.length ? dropdown : noOptionsDropdown}</>;
}
