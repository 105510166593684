import { SearchBoxFeatureSuggestion } from "@mapbox/search-js-core";
import {
    ChainedTranslator,
    chainedTranslator,
    flatten,
    resolveTemplate,
    translator
} from "@solid-primitives/i18n";
import {
    createContext,
    createMemo,
    createSignal,
    ParentProps,
    useContext,
    Signal,
    Resource
} from "solid-js";
import { createStore, SetStoreFunction } from "solid-js/store";

import { calculateBoundingBox, parseCountryCodes } from "./countries";
import { Format } from "./formats";
import { Language, Translations, dictionaries } from "./languages";
import { MapboxSearch } from "./mapboxSearch";
import { OpeningHours } from "./openingHours";
import { Service } from "./services";
import {
    AvailableFilters,
    FilterParams,
    SidePanelContent,
    Store
} from "./types";

interface TranslationsContext {
    t: ChainedTranslator<Translations, string>;
    currentLanguage: Language;
    setLanguage: (l: Language) => void;
}

interface AppliedFilters {
    search: { text: string };
    formats: Format[];
    services: Service[];
    openingHours: OpeningHours;
}

export interface AllStores {
    stores: Store[];
    appliedFilters: AppliedFilters;
    selectedStore: Store | undefined;
    sidePanelContent: "storeOverlay" | "filterControls" | undefined;
}

const TranslatorContext = createContext<TranslationsContext>();

export function useTranslator() {
    return useContext(TranslatorContext)!;
}

interface StoreMapContext {
    mapboxAccessToken: string;
    mapboxSearch: MapboxSearch;
    stores: Resource<Store[]>;
    availableFilters: Resource<AvailableFilters>;
    mapOptions: FilterParams;
    selectedPlaceSignal: Signal<SearchBoxFeatureSuggestion | undefined>;
    defaultBounds: mapboxgl.LngLatBounds;
    appliedFilterStore: [
        get: AppliedFilters,
        set: SetStoreFunction<AppliedFilters>
    ];
    sidePanelContentSignal: Signal<SidePanelContent>;
}

const StoreMapContext = createContext<StoreMapContext>();

export function useStoreMapContext() {
    return useContext(StoreMapContext)!;
}

export default function ContextProvider(
    props: ParentProps<{
        mapboxAccessToken: string;
        mapboxSearch: MapboxSearch;
        mapOptions: FilterParams;
        stores: Resource<Store[]>;
        availableFilters: Resource<AvailableFilters>;
        languageSignal: Signal<Language>;
        selectedPlaceSignal: Signal<SearchBoxFeatureSuggestion | undefined>;
    }>
) {
    // eslint-disable-next-line solid/reactivity
    const [language, setLanguage] = props.languageSignal;

    const dict = createMemo(() => flatten(dictionaries[language()]));
    const t: ChainedTranslator<Translations, string> = chainedTranslator(
        dictionaries.en,
        // eslint-disable-next-line solid/reactivity
        translator(dict, resolveTemplate)
    );

    const initialAppliedFilter: AppliedFilters = {
        search: {
            // eslint-disable-next-line solid/reactivity
            text: props.mapOptions.search || ""
        },
        formats: [],
        services: [],
        openingHours: "all"
    };

    // eslint-disable-next-line solid/reactivity
    const appliedFilterStore =
        createStore<AppliedFilters>(initialAppliedFilter);

    // eslint-disable-next-line solid/reactivity
    const sidePanelContentSignal = createSignal<SidePanelContent>(undefined);

    return (
        <TranslatorContext.Provider
            // eslint-disable-next-line solid/reactivity
            value={{
                t,
                setLanguage: setLanguage,
                currentLanguage: language()
            }}
        >
            <StoreMapContext.Provider
                value={{
                    // eslint-disable-next-line solid/reactivity
                    mapboxAccessToken: props.mapboxAccessToken,
                    // eslint-disable-next-line solid/reactivity
                    mapboxSearch: props.mapboxSearch,
                    // eslint-disable-next-line solid/reactivity
                    mapOptions: props.mapOptions,
                    // eslint-disable-next-line solid/reactivity
                    stores: props.stores,
                    // eslint-disable-next-line solid/reactivity
                    availableFilters: props.availableFilters,
                    // eslint-disable-next-line solid/reactivity
                    selectedPlaceSignal: props.selectedPlaceSignal,
                    defaultBounds: calculateBoundingBox(
                        // eslint-disable-next-line solid/reactivity
                        parseCountryCodes(props.mapOptions.countryCodes)
                    ),
                    appliedFilterStore: appliedFilterStore,
                    sidePanelContentSignal: sidePanelContentSignal
                }}
            >
                {props.children}
            </StoreMapContext.Provider>
        </TranslatorContext.Provider>
    );
}
