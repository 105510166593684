import { Feature, Point } from "geojson";
import { GeoJSONSource, IControl, LngLatBoundsLike, Map } from "mapbox-gl";
import {
    Signal,
    createSignal,
    For,
    createEffect,
    Show,
    Setter
} from "solid-js";
import MapGL, {
    Viewport,
    Source,
    Image,
    Layer,
    useMapContext,
    Control,
    Marker
} from "solid-map-gl";

import { useStoreMapContext } from "../contextProviders";
import { supportedFormats } from "../formats";
import { SidePanelContent, Store } from "../types";
import { storesToGeoJson } from "../utils";

const getBoundingBox = (
    coords: { latitude: number; longitude: number }[]
): LngLatBoundsLike => {
    const lats = coords.map((p) => p.latitude);
    const lngs = coords.map((p) => p.longitude);

    return [
        { lat: Math.min(...lats), lng: Math.min(...lngs) },
        { lat: Math.max(...lats), lng: Math.max(...lngs) }
    ];
};

class ResetZoomControl implements IControl {
    #container?: HTMLDivElement;

    constructor(
        private sidePanelContentSignal: Signal<SidePanelContent>,
        private defaultBounds: mapboxgl.LngLatBounds
    ) {}

    onAdd(map: Map) {
        const [_, setSidePanelContent] = this.sidePanelContentSignal;
        this.#container = document.createElement("div");
        this.#container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

        const icon = document.createElement("div");
        icon.className = "mapgxgl-ctrl-icon";
        icon.innerHTML =
            '<svg style="margin: auto" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M21 21l-6 -6"></path><path d="M3.268 12.043a7.017 7.017 0 0 0 6.634 4.957a7.012 7.012 0 0 0 7.043 -6.131a7 7 0 0 0 -5.314 -7.672a7.021 7.021 0 0 0 -8.241 4.403"></path><path d="M3 4v4h4"></path></svg>';

        const button = document.createElement("button");
        button.appendChild(icon);

        this.#container.appendChild(button);

        this.#container.addEventListener("click", (_e) => {
            setSidePanelContent(undefined);

            map.fitBounds(this.defaultBounds);
        });

        return this.#container;
    }

    onRemove() {
        this.#container?.parentNode?.removeChild(this.#container);
    }
}

function ImagesForFormats() {
    return (
        <For each={supportedFormats}>
            {(format) => {
                const image = `${import.meta.env.VITE_CDN_BASE_URL}images/marker-${format}.png`;
                return <Image id={image} source={image} />;
            }}
        </For>
    );
}

function StorePins(props: { stores: Store[]; setCursorStyle: Setter<string> }) {
    const [ctx] = useMapContext();
    const {
        appliedFilterStore,
        sidePanelContentSignal,
        selectedPlaceSignal: [selectedPlace]
    } = useStoreMapContext();
    const [appliedFilters] = appliedFilterStore;
    const [_, setSidePanelContent] = sidePanelContentSignal;

    const filteredStores = () => {
        let filteredStores = props.stores;

        switch (appliedFilters.openingHours) {
            case "all":
                break;
            case "openAwhile":
                filteredStores = filteredStores.filter((s) => {
                    const todaysOpeningHours = s.calculatedOpeningHours.find(
                        (oh) =>
                            oh.timeLeft !== undefined &&
                            oh.timeLeft !== "closed"
                    )!;
                    const hoursRemaining = parseInt(
                        todaysOpeningHours?.timeLeft!.split(":")[0] ?? "0",
                        10
                    );

                    return hoursRemaining >= 1;
                });
                break;
            case "open":
                filteredStores = filteredStores.filter((s) => {
                    const todaysOpeningHours = s.calculatedOpeningHours.find(
                        (oh) => oh.timeLeft !== undefined
                    )!;

                    return todaysOpeningHours.timeLeft !== "closed";
                });
                break;
        }

        if (appliedFilters.formats.length > 0) {
            filteredStores = filteredStores.filter((s) =>
                appliedFilters.formats.includes(s.format)
            );
        }

        if (appliedFilters.services.length > 0) {
            const selectedServices = appliedFilters.services;

            filteredStores = filteredStores.filter((s) =>
                s.services.some((service) => selectedServices.includes(service))
            );
        }

        return filteredStores;
    };

    const geoJson = () => storesToGeoJson(filteredStores());

    return (
        <>
            <Show when={selectedPlace() && !selectedPlace()?.properties.bbox}>
                <Marker
                    lngLat={
                        selectedPlace()?.geometry
                            .coordinates as mapboxgl.LngLatLike
                    }
                    options={{ color: "#B6163D" }}
                />
            </Show>
            <ImagesForFormats />
            <Show when={!!geoJson().features.length}>
                <Source
                    id={"stores"}
                    source={{
                        type: "geojson",
                        data: geoJson(),
                        cluster: true,
                        clusterRadius: 50
                    }}
                >
                    <Layer
                        id={"markers"}
                        filter={["!", ["has", "point_count"]]}
                        style={{
                            type: "symbol",
                            layout: {
                                "icon-image": ["get", "marker"],
                                "icon-size": 0.2,
                                "icon-anchor": "bottom",
                                "icon-allow-overlap": true
                            }
                        }}
                        onMouseEnter={() => {
                            props.setCursorStyle("pointer");
                        }}
                        onMouseLeave={() => {
                            props.setCursorStyle("grab");
                        }}
                        onClick={(e) => {
                            const features = e.target.queryRenderedFeatures(
                                e.point,
                                {
                                    // @ts-expect-error 'layerIdList' is dynamically added to the target element
                                    layers: e.target.layerIdList
                                }
                            );

                            if (!features.length) {
                                return;
                            }

                            const store = geoJson().features.find(
                                (s) =>
                                    (s.properties! as Store).id ===
                                    (features[0]!.properties! as Store).id
                            ) as Feature<Point, Store>;

                            ctx.map.flyTo({
                                center: store.geometry.coordinates as [
                                    number,
                                    number
                                ],
                                zoom:
                                    ctx.map.getZoom() >= 12
                                        ? ctx.map.getZoom()
                                        : 12
                            });

                            setSidePanelContent(store.properties);
                        }}
                    />
                    <Layer
                        id="clusters"
                        style={{
                            type: "circle",
                            paint: {
                                "circle-color": "#172E48",
                                "circle-radius": 15,
                                "circle-pitch-scale": "viewport"
                            }
                        }}
                        onMouseEnter={() => {
                            props.setCursorStyle("pointer");
                        }}
                        onMouseLeave={() => {
                            props.setCursorStyle("grab");
                        }}
                        onClick={(e) => {
                            const features = e.target.queryRenderedFeatures(
                                e.point
                            );
                            if (!features.length) {
                                return;
                            }

                            const clusterId =
                                features[0].properties!.cluster_id;
                            const pointCount =
                                features[0].properties!.point_count;
                            const sourceId = features[0].source;

                            (
                                ctx.map.getSource(sourceId) as GeoJSONSource
                            ).getClusterLeaves(
                                clusterId,
                                pointCount,
                                0,
                                (_err, clusterPoints) => {
                                    if (!clusterPoints) {
                                        return;
                                    }

                                    // Bounds need to be in order before calling fitBounds
                                    const coordinates = clusterPoints.map(
                                        (p) => p.properties!.coordinates
                                    );

                                    const bounds = getBoundingBox(coordinates);

                                    ctx.map.fitBounds(bounds, { padding: 50 });
                                }
                            );
                        }}
                    />
                    <Layer
                        id={"count"}
                        style={{
                            type: "symbol",
                            layout: {
                                "text-field": [
                                    "get",
                                    "point_count_abbreviated"
                                ],
                                "text-font": [
                                    "Inter Bold",
                                    "Arial Unicode MS Regular"
                                ],
                                "text-size": 12,
                                "text-anchor": "center",
                                "text-offset": [0, 0.08]
                            },
                            paint: {
                                "text-color": "#ffffff"
                            }
                        }}
                        filter={["has", "point_count"]}
                    />
                </Source>
            </Show>
        </>
    );
}

export function StoreMap() {
    const [ctx] = useMapContext();
    const { mapboxAccessToken, stores, sidePanelContentSignal, defaultBounds } =
        useStoreMapContext();

    const [sidePanelContent, setSidePanelContent] = sidePanelContentSignal;

    createEffect(() => {
        if (!ctx.map) {
            return;
        }

        ctx.map.addControl(
            new ResetZoomControl(sidePanelContentSignal, defaultBounds),
            "bottom-right"
        );

        ctx.map.on("sourcedata", () => {
            ctx.map.setFilter("clusters", ["has", "point_count"]);
        });
    });

    const defaultViewport = { bounds: defaultBounds } as Viewport;

    const [viewport, setViewport] = createSignal(defaultViewport);
    const [cursorStyle, setCursorStyle] = createSignal("grab");

    return (
        <>
            <MapGL
                class="h-full w-full"
                options={{
                    style: "mapbox://styles/mapbox/streets-v12",
                    accessToken: mapboxAccessToken
                }}
                viewport={viewport()}
                onViewportChange={setViewport}
                cursorStyle={cursorStyle()}
                onClick={() => {
                    if (sidePanelContent()) {
                        setSidePanelContent(undefined);
                    }
                }}
            >
                <Control
                    type="navigation"
                    position="bottom-right"
                    options={{ showCompass: false }}
                />
                <Control
                    type="geolocate"
                    position="bottom-right"
                    options={{ enableHighAccuracy: true }}
                />
                <Show when={stores()}>
                    <StorePins
                        stores={stores()!}
                        setCursorStyle={setCursorStyle}
                    />
                </Show>
            </MapGL>
        </>
    );
}
