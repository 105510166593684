import { Show } from "solid-js";
import { Transition } from "solid-transition-group";

import { FilterControls } from "./filters/filterControls";
import { StoreOverlay } from "./storeOverlay";
import { useStoreMapContext } from "../contextProviders";

export function SidePanel() {
    const { sidePanelContentSignal, availableFilters } = useStoreMapContext();
    const [sidePanelContent] = sidePanelContentSignal;

    const classes = () =>
        `border-trb-gray-200 absolute z-10 h-full overflow-y-auto border bg-white w-full ${sidePanelContent() === "filterControls" ? "sm:w-96" : "sm:w-72"}`;

    return (
        <Transition
            mode="outin"
            enterClass="max-sm:translate-y-full sm:-translate-x-full"
            exitToClass="max-sm:translate-y-full sm:-translate-x-full"
            enterActiveClass="transition-all duration-300 ease-in-out"
            exitActiveClass="transition-all duration-300 ease-in-out"
        >
            <Show when={sidePanelContent() === "filterControls"}>
                <section class={classes()}>
                    <Show when={availableFilters()}>
                        <FilterControls
                            availableFilters={availableFilters()!}
                        />
                    </Show>
                </section>
            </Show>
            <Show when={typeof sidePanelContent() === "object"}>
                <section class={classes()}>
                    <StoreOverlay />
                </section>
            </Show>
        </Transition>
    );
}
