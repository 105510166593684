import {
    SearchBoxCore,
    SearchBoxFeatureSuggestion,
    SearchBoxSuggestion,
    SearchBoxSuggestionResponse,
    SearchSession
} from "@mapbox/search-js-core";

import { SupportedCountry } from "./countries";
import { Language } from "./languages";

export interface MapboxSearch {
    suggest: (
        countries: SupportedCountry[],
        language: Language,
        query: string,
        limit: number
    ) => Promise<SearchBoxSuggestion[]>;
    retrieve: (
        language: Language,
        mapboxId: string
    ) => Promise<SearchBoxFeatureSuggestion>;
}

export const mapboxSearch = (accessToken: string): MapboxSearch => {
    const searchSession = new SearchSession(
        new SearchBoxCore({ accessToken }),
        300
    );

    const suggest = async (
        countries: SupportedCountry[],
        language: Language,
        query: string,
        limit: number
    ) => {
        const url = new URL(
            "https://api.mapbox.com/search/searchbox/v1/suggest"
        );
        url.search = new URLSearchParams({
            access_token: accessToken,
            session_token: searchSession.sessionToken.toString(),
            q: query,
            country: countries.join(","),
            language: language,
            types: "region,postcode,district,place,city,locality,neighborhood,street,address,poi,block",
            limit: limit.toString()
        }).toString();

        const response = await fetch(url);
        const data = (await response.json()) as SearchBoxSuggestionResponse;

        return data.suggestions;
    };

    const retrieve = async (language: Language, mapboxId: string) => {
        const url = new URL(
            `https://api.mapbox.com/search/searchbox/v1/retrieve/${mapboxId}`
        );
        url.search = new URLSearchParams({
            access_token: accessToken,
            session_token: searchSession.sessionToken.toString(),
            language: language
        }).toString();

        const response = await fetch(url);
        return (await response.json())
            .features[0] as SearchBoxFeatureSuggestion;
    };

    return {
        suggest,
        retrieve
    };
};
