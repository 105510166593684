export type Format = keyof typeof formats;

// Keep this list in alphabetical order.
const formats = {
    avec: "avec",
    backfactory: "BACK-FACTORY",
    backwerk: "Backwerk",
    brezelkoenig: "Brezelkönig",
    ditsch: "Ditsch",
    kkiosk: "k kiosk",
    pressAndBooks: "Press & Books",
    spettacolo: "Caffè Spettacolo",
    superguud: "Superguud"
} as const;

export const supportedFormats = Object.keys(formats) as Format[];

export const isSupportedFormat = (format: string): format is Format =>
    Object.hasOwn(formats, format);

export const prettyPrintFormat = (format: Format): string => formats[format];
