import { FeatureCollection } from "geojson";

import { useTranslator } from "./contextProviders";
import { parseLanguage } from "./languages";
import { FilterParams, Store } from "./types";

export function storesToGeoJson(stores: Store[]): FeatureCollection {
    const geoJson: FeatureCollection = {
        type: "FeatureCollection",
        features: stores.map((store, index) => {
            return {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [
                        parseFloat(store.coordinates.longitude),
                        parseFloat(store.coordinates.latitude)
                    ]
                },
                properties: {
                    index: index,
                    marker: `${import.meta.env.VITE_CDN_BASE_URL}images/marker-${store.format}.png`,
                    ...store
                }
            };
        })
    };

    return geoJson;
}

const buildURLSearchParams = (filterParams?: FilterParams) => {
    if (filterParams == undefined) {
        return "";
    } else {
        return `?${new URLSearchParams(filterParams!).toString()}`;
    }
};

const convertToFilterParams = (
    urlSearchParams?: URLSearchParams
): FilterParams | undefined => {
    if (urlSearchParams == undefined) {
        return undefined;
    } else {
        return {
            countryCodes:
                urlSearchParams.getAll("countryCodes").join(",") || "",
            formats: urlSearchParams.getAll("formats").join(",") || "",
            services: urlSearchParams.getAll("services").join(",") || "",
            search: urlSearchParams.get("search") || "",
            language: parseLanguage(urlSearchParams.get("language") || "")
        };
    }
};

export const buildStoreFinderOptions = (
    filterParams?: FilterParams | URLSearchParams
) => {
    let urlParams = "";
    let parsedMapOptions: FilterParams | undefined = undefined;

    // filterParams is a URLSearchParams instance
    if (!!filterParams && "size" in filterParams && "entries" in filterParams) {
        urlParams = `?${new URLSearchParams(filterParams!).toString()}`;
        parsedMapOptions = convertToFilterParams(
            filterParams as URLSearchParams
        );
        // filterParams should be an object of the FilterParams shape
    } else {
        urlParams = buildURLSearchParams(
            filterParams as FilterParams | undefined
        );
        parsedMapOptions = filterParams as FilterParams | undefined;
    }

    return { urlParams, parsedMapOptions };
};

export const localizeOpeningHour = (openingHour: string) => {
    const { t } = useTranslator();

    switch (openingHour) {
        case "openAwhile":
            return t.sf__filters__opening_hours_closing_soon();
        case "open":
            return t.sf__filters__opening_hours_open();
        default:
            return openingHour;
    }
};
