import { Setter } from "solid-js";

import { useTranslator } from "../../contextProviders";
import { Format, prettyPrintFormat, supportedFormats } from "../../formats";
import { MultiSelectDropdown } from "../multiSelectDropdown";

export function FormatsSelect(props: {
    selectedFormats: Format[];
    setSelectedFormats: Setter<Format[]>;
}) {
    const { t } = useTranslator();

    const formatsDisplayText = () => {
        if (props.selectedFormats.length === 0) {
            return t.sf__filters__format_none_selected();
        } else if (props.selectedFormats.length > 1) {
            const count = props.selectedFormats.length;
            return t.sf__filters__format_multiple_selected({ count });
        } else {
            const format = props.selectedFormats[0];
            return prettyPrintFormat(format);
        }
    };

    return (
        <MultiSelectDropdown
            label={t.sf__filters__format_label()}
            displayText={formatsDisplayText()}
            options={supportedFormats}
            selectedOptions={props.selectedFormats}
            formatOption={prettyPrintFormat}
            onChange={props.setSelectedFormats}
        />
    );
}
