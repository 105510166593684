/* @refresh reload */
import { SearchBoxFeatureSuggestion } from "@mapbox/search-js-core";
import { createEffect, createResource, createSignal } from "solid-js";
import { render } from "solid-js/web";

import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";

import { fetchAllStores, fetchAvailableFilters } from "./api";
import { Filters } from "./components/filters/filters";
import { SidePanel } from "./components/sidePanel";
import { StoreMap } from "./components/storeMap";
import ContextProvider from "./contextProviders";
import { parseCountryCodes } from "./countries";
import { Language, defaultLanguage } from "./languages";
import { mapboxSearch } from "./mapboxSearch";
import { FilterParams } from "./types";
import { buildStoreFinderOptions } from "./utils";

function getInputRootAsHtmlElement(inputRoot: string | HTMLElement) {
    if (typeof inputRoot === "string") {
        return document.getElementById(inputRoot) as HTMLElement;
    } else {
        return inputRoot as HTMLElement;
    }
}

const deprecatedMapboxToken =
    "pk.eyJ1IjoidmFsb3JhLWRpZ2l0YWwiLCJhIjoiY2x1d2ZpcGdjMGZhMzJqcXI5Mm9zZmluYyJ9.VGSL9NxCEpuFpdyNggodRQ";

const storeFinderMap = (
    inputRoot: string | HTMLElement,
    mapboxAccessToken?: string | FilterParams | URLSearchParams,
    filterParams?: FilterParams | URLSearchParams
) => {
    /// Backwards compatibility with invocations that didn't pass a Mapbox access token.
    if (typeof mapboxAccessToken !== "string") {
        filterParams = mapboxAccessToken;
        mapboxAccessToken = deprecatedMapboxToken;
    }

    if (mapboxAccessToken === deprecatedMapboxToken) {
        console.error(
            "You are using a deprecated access token for Mapbox that is not intended for production and will stop working soon. Please contact Valora Digital to obtain the correct Mapbox access token."
        );
    }

    const { urlParams, parsedMapOptions } =
        buildStoreFinderOptions(filterParams);

    const root = getInputRootAsHtmlElement(inputRoot);

    let isInitialized = false;

    const allStoresPromise = fetchAllStores(urlParams);
    const availableFiltersPromise = fetchAvailableFilters(urlParams);

    const [stores] = createResource(() => allStoresPromise);
    const [availableFilters] = createResource(() => availableFiltersPromise);

    const [language, setLanguage] = createSignal<Language>(
        parsedMapOptions?.language || defaultLanguage
    );

    const [selectedPlace, setSelectedPlace] = createSignal<
        SearchBoxFeatureSuggestion | undefined
    >(undefined);

    const _mapboxSearch = mapboxSearch(mapboxAccessToken);

    const search = async (query: string) => {
        const suggestions = await _mapboxSearch.suggest(
            parseCountryCodes(parsedMapOptions!.countryCodes),
            language(),
            query,
            1
        );
        if (suggestions.length > 0) {
            const place = await _mapboxSearch.retrieve(
                language(),
                suggestions[0].mapbox_id
            );
            setSelectedPlace(place);
        }
    };

    createEffect(() => {
        if (parsedMapOptions?.search) {
            search(parsedMapOptions.search);
        }
    });

    new IntersectionObserver((entries) => {
        if (isInitialized || !entries[0].isIntersecting) {
            return;
        }

        isInitialized = true;
        render(
            () => (
                <ContextProvider
                    mapboxAccessToken={mapboxAccessToken}
                    mapboxSearch={_mapboxSearch}
                    stores={stores}
                    availableFilters={availableFilters}
                    mapOptions={parsedMapOptions!}
                    languageSignal={[language, setLanguage]}
                    selectedPlaceSignal={[selectedPlace, setSelectedPlace]}
                >
                    <Filters />
                    <SidePanel />
                    <StoreMap />
                </ContextProvider>
            ),
            root
        );
    }).observe(root);

    return { setLanguage };
};

export { storeFinderMap };
